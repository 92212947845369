<template lang="html">
  <div class="card card-primary card-outline PanoList">
    <div class="overlay" v-if="$store.state.loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <div class="card-header p-2">
      <h3 class="card-title">全景管理</h3>
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-1">
      <div class="mailbox-controls p-0">
        <button class="btn btn-sm btn-primary mx-1" title="添加" @click="Create()"><i class="fa fa-plus"></i></button>
        <button class="btn btn-sm btn-default mx-1" title="刷新" @click="loadData()"><i class="fas fa-sync"></i></button>
        <div class="float-right">
          <div class="dropdown">
            <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              筛选
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">标签</span>
                  </div>
                  <select class="form-control form-control-sm" v-model:value="query.tags" v-on:change="search()" placeholder="标签">
                    <option :value="null">所有</option>
                    <optgroup label="私有">
                      <option v-for="i in privateTags" v-bind:value="i.name">{{i.name}}</option>
                    </optgroup>
                    <optgroup label="公共">
                      <option v-for="i in publicTags" v-bind:value="i.name">{{i.name}}</option>
                    </optgroup>
                  </select>
                </div>
              </form>
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm" @dblclick="query.beginTime=null;search()">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">起始</span>
                  </div>
                  <input type="date" class="form-control form-control-sm" v-model="query.beginTime" v-on:change="search()">
                </div>
              </form>
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm" @dblclick="query.endTime=null;search()">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">结束</span>
                  </div>
                  <input type="date" class="form-control form-control-sm" v-model="query.endTime" v-on:change="search()">
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioHasGps1" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioHasGps1">有GPS</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioHasGps2" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioHasGps2">无GPS</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioHasGps3" name="customRadioHasGps" class="custom-control-input" v-model="query.hasGps" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioHasGps3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioPrivate1" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioPrivate1">私有</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioPrivate2" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioPrivate2">公开</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioPrivate3" name="customRadioPrivate" class="custom-control-input" v-model="query.private" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioPrivate3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFine1" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFine1">精华</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFine2" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFine2">普通</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFine3" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFine3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFeatured1" name="customRadioFeatured" class="custom-control-input" v-model="query.featured" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFeatured1">精选</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFeatured2" name="customRadioFeatured" class="custom-control-input" v-model="query.featured" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFeatured2">默认</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioFeatured3" name="customRadioFeatured" class="custom-control-input" v-model="query.featured" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioFeatured3">不限</label>
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed1" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed1">关闭</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed2" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed2">打开</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed3" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed3">不限</label>
                </div>
              </form>
              <!--<form class="dropdown-item p-1">
                  <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitchPrivate" v-model="query.private" v-on:change="search()">
                      <label class="custom-control-label" for="customSwitchPrivate">私有</label>
                  </div>
              </form>
              <form class="dropdown-item p-1">
                  <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitchFine" v-model="query.fine" v-on:change="search()">
                      <label class="custom-control-label" for="customSwitchFine">精华</label>
                  </div>
              </form>
              <form class="dropdown-item p-1">
                  <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitchFeatured" v-model="query.featured" v-on:change="search()">
                      <label class="custom-control-label" for="customSwitchFeatured">精选</label>
                  </div>
              </form>-->
            </div>
          </div>
        </div>
        <!-- /.pull-right -->
        <ul class="nav nav-tabs nav-nowrap my-1">
          <li class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.state==null}" :to="{  query: {...$route.query,page:undefined, state: undefined }}">
              全部
            </router-link>
          </li>
          <li v-for="i in status" class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.state==i.value}" :to="{ query: {...$route.query,page:undefined, state: i.value }}">
              {{i.name}}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="row">
        <div v-if="loading" class="col-12"><i class="fas fa-spinner fa-pulse"></i> 加载中…</div>
        <div v-else class="col-sm-6 col-md-6 col-lg-4 col-xl-3" v-for="i in list">
          <div class="card card-widget widget-user-2">
            <!-- Add the bg color to the header using any of the bg-* classes -->
            <a class="widget-user-header bg-yellow" style="height: 150px;" v-bind:style="resizeimg(i.firstImage?i.firstImage.url:'')" :href="setting.siteUrl+'/P/'+i.key" target="_blank" :class="{disabled:i.state<=1}">
              <!-- /.widget-user-image -->
              <h4 class="">{{i.title}}</h4>
              <h5 class="text-truncate">{{i.description}}</h5>
              <h6 v-if="(i.gpsLat!=0 || i.gpsLng!=0)&&i.country" class="">
                <i class="fas fa-map-marker-alt"></i>&nbsp;
                <span v-if="i.country!='中华人民共和国'&&i.country!='中国'" style="white-space: nowrap;">{{i.country}}</span>
                <span style="white-space: nowrap;">{{i.province}}</span>
                <span style="white-space: nowrap;">{{i.city}}</span>
                <span style="white-space: nowrap;">{{i.district}}</span>
                <span style="white-space: nowrap;">{{i.street}}</span>
              </h6>
              <h6 v-if="i.editor" class="text-truncate">
                <span v-if="i.editor.userName != currentUser.userName">
                  <i class="fas fa-user-edit"></i> {{i.editor.nickName||i.editor.userName}}
                </span>
                <span v-else>
                  <i class="fas fa-user"></i> {{i.user.nickName||i.user.userName}}
                </span>
              </h6>
              <div class="widget-info px-2">
                <h6 class="text-right">
                  <template v-if="i.expiryTime">
                    <time v-if="new Date(i.expiryTime)>new Date()" class="text-shadow text-lime" :title="i.expiryTime">Pro 功能 {{$moment(i.expiryTime).fromNow()}}过期</time>
                    <time v-else class="text-shadow text-orange" :title="i.expiryTime">Pro 功能 {{$moment(i.expiryTime).format("YYYY-MM-DD HH:mm")}} 已过期</time>
                  </template>
                </h6>
                <h6>
                  <span title="观看"><i class="fas fa-eye"></i> {{i.viewCount}}</span>
                  <span title="点赞"><i class="fas fa-thumbs-up"></i> {{i.praiseCount}}</span>
                  <span><i class="fas fa-map-marker-alt" title="地图坐标" v-if="i.gpsLat!=0 || i.gpsLng!=0"></i></span>
                  <span v-if="i.state<=1">{{i.status}}</span>
                  <span v-if="i.private">私有</span>
                  <span v-if="i.fine" class="text-warning">精</span>
                  <span v-if="i.featured"><i class="fas fa-star"></i></span>
                  <span>{{i.checkNote}}</span>
                  <time class="float-right" :title="i.addTime">{{$moment(i.addTime).fromNow()}}</time>
                </h6>
              </div>
            </a>
            <div class="card-footer p-0">
              <ul class="nav nav-stacked">
                <li class="">
                  <!--<button class="btn btn-sm btn-default m-1">
                    <i class="fa fa-qrcode"></i>
                  </button>-->
                  <button v-if="!i.featured" type="button" class="btn btn-sm btn-warning m-1" @click="featured(i)" :disabled="updateing&&i.state<=1" title="加星">加星</button>
                  <button v-if="i.featured" type="button" class="btn btn-sm btn-warning m-1" @click="unFeatured(i)" :disabled="updateing&&i.state<=1" title="取星">取星</button>
                  <button class="btn btn-sm btn-warning m-1" title="修改" @click="edit(i)" :disabled="i.state<=1">
                    修改
                  </button>
                  <a class="btn btn-sm btn-primary m-1" title="分享" :href="setting.siteUrl+'/P/'+i.key" target="_blank" :class="{disabled:i.state<=1}">
                    <i class="fas fa-share-square"></i>
                  </a>
                  <button class="btn btn-sm btn-warning m-1" @click="$router.push({name: 'Buy', params: {productId: '全景年费'}, query: {id: i.guid}})" :disabled="i.state<=1">
                    <i class="fas fa-yen-sign"></i>
                  </button>
                  <div class="btn-group dropup">
                    <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-ellipsis-h"></i>
                    </button>
                    <div class="dropdown-menu  dropdown-menu-right">
                      <!--<button v-if="!i.featured" type="button" class="dropdown-item" @click="featured(i)" :disabled="updateing" title="加星">加星</button>
                      <button v-if="i.featured" type="button" class="dropdown-item" @click="unFeatured(i)" :disabled="updateing" title="取星">取星</button>-->
                      <button class="dropdown-item" @click="$router.push({ name: 'CommentList', query: { articleGuid: i.guid }})">
                        留言管理
                      </button>
                      <button class="dropdown-item text-danger" @click="setOwnerDialog(i)" :disabled="i.state<=1">
                        转让所有权
                      </button>
                      <button class="dropdown-item text-danger" @click="delDialog(i)" :disabled="i.state==1">
                        删除
                      </button>
                    </div>
                  </div>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              :prePageSize="pager.prePageSize"
              :onPagerChange="pageChange">
      </paging>
    </div>
    <Modal ref="uploadModal">
      <template slot="header">
        <!--<h4 class="modal-title" id="uploadModalLabel">上传全景</h4>-->
        <ul class="nav nav-pills">
          <li class="nav-item"><a class="nav-link active" href="#image" id="btn_image" data-toggle="tab">全景图片</a></li>
          <li v-if="$inRole('管理员')" class="nav-item"><a class="nav-link" href="#video" id="btn_video" data-toggle="tab">全景视频</a></li>
        </ul>
      </template>
      <div class="tab-content">
        <div class="container tab-pane active" id="image">
          <UploadBox :action="uploadUrl"
                     :param="param"
                     :multiple="true"
                     :maxSize="50"
                     :maxWidth="22000"
                     :maxHeight="11000"
                     :isPano="true"
                     :allowExtensions="/(.jpg|.jpeg)$/i"
                     :partSizeMB="10"
                     :surlheight="100"
                     :surlwidth="200"
                     :maxUpper="3"
                     :uploadFinished="search"
                     :changeName="changePanoName">
            选择图片
          </UploadBox>
        </div>
        <div class="container tab-pane" id="video">
          <UploadBox :action="uploadUrl"
                     :param="param"
                     :multiple="true"
                     :maxSize="500"
                     :allowExtensions="/(.mp4)$/i"
                     :partSizeMB="10"
                     :maxUpper="3"
                     :uploadFinished="search"
                     :changeName="changePanoName">
            选择视频
          </UploadBox>
        </div>
      </div>
      <template slot="footer">
        <span hidden>hidden</span>
      </template>

    </Modal>
    <Modal ref="setOwnerModal">
      <template slot="header">
        <h4 class="modal-title">转让所有权</h4>
      </template>
      <h4 v-if="qrcodeLoading" class="text-center">
        <i class="fas fa-sync fa-spin"></i>
      </h4>
      <img v-else :src="qrcode" class="img-fluid" />
      <template slot="footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
      </template>
    </Modal>
    <Modal ref="delModal">
      <template slot="header">
        <h4 class="modal-title">删除全景</h4>
      </template>
      是否删除
      <template slot="footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
        <button type="button" class="btn btn-primary" @click="del(item)">确定</button>
      </template>

    </Modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Modal, UploadBox } from 'common'
  //import UploadBox from '@/components/UploadBox'
  //import Modal from '@/components/Modal'

  export default {
    components: {
      UploadBox,
      Modal,
    },
    data() {
      return {
        item: {},
        checkedNames: [],
        publicTags: [],
        privateTags: [],
        status: [
          { name: '待切片', value: 0 },
          { name: '切片中', value: 1 },
          { name: '待收录', value: 2 },
          { name: '已收录', value: 4 },
          { name: '未收录', value: 3 },
        ],
        //status: [
        //  { name: '待切片', value: '待切片' },
        //  { name: '切片中', value: '切片中' },
        //  { name: '待收录', value: '待收录' },
        //  { name: '已收录', value: '已收录' },
        //  { name: '未收录', value: '未收录' },
        //],
        pager: {
          prePageSize: [12, 24, 48, 96],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: { ...this.getDefaultQuery(), ...this.$route.query },
        files: [],
        progress: '0%',
        showProgress: false,
        showProgressErro: false,
        ProgressErro: '',
        fileList: [],
        uploadUrl: this.$route.params.groupId ? `/Api/GroupAdmin/Panos/${this.$route.params.groupId}/Upload` : '/Api/My/Panos/Upload',
        param: this.newParam(),
        list: null,
        loading: false,
        qrcodeLoading: false,
        qrcode: null,
        updateing: false,
      }
    },
    created() {
      this.loadData()
      //this.$store.state.pageinfo.main.title = "用户管理"
      //this.setPageInfo("用户管理")

    },
    computed: {
      ...mapGetters([
        'setting',
        'currentUser'
      ]),
      tags: function () {
        return [...this.publicTags, ...this.privateTags]
      },
    },
    methods: {
      newParam() {
        var p = {}
        return p
      },
      loadData() {
        this.loading = true
        this.loadTags()
        var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/My/Panos` : `/Api/My/Panos`;
        var config = {
          params: {
            ...this.query
          }
        }
        if (this.$route.params.groupId) {
          url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/GroupAdmin/Panos` : `/Api/GroupAdmin/Panos`;
          config = {
            params: {
              groupId: this.$route.params.groupId,
              ...this.query
            }
          }
        }
        this.$axios.get(url, config).then((res) => {
          this.pager.pageIndex = res.data.pageIndex;
          this.pager.pageSize = res.data.pageSize;
          this.pager.totalPages = res.data.totalPages;
          this.pager.totalRecords = res.data.totalRecords;
          this.list = res.data.data;
          this.loading = false
        })
      },
      loadTags() {
        var tagtype = 11;
        this.$axios.get('/Api/My/Tags/All', { params: { type: tagtype } })
          .then((response) => {
            //console.log(response)
            this.privateTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.$axios.get('/Api/Tags/All', { params: { type: tagtype } })
          .then((response) => {
            //console.log(response)
            this.publicTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      getDefaultQuery() {
        return {
          page: 1,
          pageSize: 12,
          sort: "id",
          order: "desc",
          fields: null,
          key: null,
          tags: null,
          state: null,
          private: null,
          fine: null,
          featured: null,
          closed: null,
          hasGps: null,
          beginTime: null,
          endTime: null,
        }
      },
      getDifferentQuery(query) {
        var dif = {}
        var all = { ...this.query, ...query }
        var def = this.getDefaultQuery()
        for (var i in all) {
          if (def[i] != all[i]) {
            dif[i] = all[i]
          }
        }
        return dif
      },
      setQuery(newquery) {
        if (!newquery) {
          this.query = this.getDefaultQuery()
        }
        var dif = this.getDifferentQuery(newquery)
        console.log(this.$route.query, dif)
        if (!this.$isObjectValueEqual(this.$route.query, dif)) {
          this.$router.push({ query: dif })
        }
      },
      search() {
        this.setQuery({ page: 1 })
      },
      pageChange(page, pageSize) {
        this.setQuery({ page, pageSize })
      },
      sortingChanged(ctx) {
        this.setQuery({ sort: ctx.sortBy, order: ctx.sortDesc ? "desc" : "asc" })
      },
      resizeimg(url) {
        return {
          'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',

        }
      },
      showDialog(item) {
        this.$refs.myModal.show()
        this.item = item
      },
      Create() {
        this.$refs.uploadModal.show()
      },
      edit(i) {
        if (this.$route.params.groupId) {
          this.$router.push({ name: 'GroupPanoEdit', params: { panoId: i.guid } })
        } else {
          this.$router.push({ name: 'PanoEdit', params: { panoId: i.guid } })
        }
      },
      setOwnerDialog(item) {
        this.$refs.setOwnerModal.show()
        this.item = item
        this.showQrcode(item.guid)
      },
      showQrcode(id) {
        console.log('showQrcode')
        this.qrcodeLoading = true;
        this.$axios.get(`/Api/Weixin/QRCode/SetPanoOwner/${id}`)
          .then((response) => {
            this.qrcodeLoading = false;
            console.log(response)
            this.qrcode = `${window.global.API_BASE_URL}/Api/QRCode?url=${response.data.url}`
          })
          .catch((error) => {
            console.log(error)
          })

      },
      delDialog(item) {
        this.$refs.delModal.show()
        this.item = item
      },
      del(item) {
        let _this = this
        var url = "/Api/My/Panos/" + item.guid
        this.$axios.delete(url)
          .then(function (response) {
            console.log('#', response)
            _this.loadData()
            _this.$refs.delModal.hide()
          }).catch(function (error) {
            console.log(error)
          })
      },
      addFile(event) {
        this.files = event.target.files[0];
        console.log(this.files);
      },
      //upload: function () {
      //    var _this = this
      //    _this.showProgress = true
      //    _this.showProgressErro = false
      //    var url = "/Api/My/Files/UploadPano"
      //    var files = _this.files
      //    console.log(files)
      //    var data = new FormData()
      //    //data.append("articleGUID", articleGUID); //文章 Id
      //    data.append("files", files)

      //    var config = {
      //        onUploadProgress: progressEvent => {
      //            var complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
      //            _this.progress = complete
      //            console.info('#', complete)
      //            console.info('#', progressEvent)
      //            console.info('#', progressEvent.loaded)
      //        }
      //    }
      //    this.$axios.post(url, data, config)
      //        .then(function (response) {
      //            console.log('#', response)
      //            _this.showProgressErro = true
      //            if (response.data.status === 'success') {
      //                _this.showProgress = false
      //                _this.ProgressErro = '上传成功'
      //                _this.ProgressErro = '<img style="max-width:200px;max-height:200px" src=http://localhost:5001' + response.data.url + ' />'
      //            } else {
      //                _this.ProgressErro = response.data
      //            }
      //        }).catch(function (error) {
      //            console.log(error)
      //        })
      //},
      changePanoName(item, res) {
        var _this = this;
        if (res) {
          console.log(item, res)
          var data = { ...res };
          data.title = item.title;
          console.log(data)
          this.$axios.put('/Api/My/Panos/' + data.guid, data).then(function (res) {
            if (res.status == 200) {
              _this.search()
            }
          })
        }
      },
      featured(item) {
        if (this.updateing) {
          return
        }
        this.$axios.get(`/Api/My/Panos/Featured/${item.guid}`).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '加星成功',
              type: 'info',
              zIndex: 9999,
            })
            item.featured = true
            //this.loadData()
          }
        })
      },
      unFeatured(item) {
        if (this.updateing) {
          return
        }
        this.$axios.get(`/Api/My/Panos/UnFeatured/${item.guid}`).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '取星成功',
              type: 'info',
              zIndex: 9999,
            })
            item.featured = false
            //this.loadData()
          }
        })
      },
    },
    beforeRouteUpdate(to, from, next) {
      this.query = { ...this.getDefaultQuery(), ...to.query }
      this.loadData()
      next()
    },
    //beforeRouteLeave(to, from, next) {
    //  // 导航离开该组件的对应路由时调用
    //  // 可以访问组件实例 `this`
    //  $('.modal').modal('hide')
    //  next()
    //}
  }
</script>

<style lang="css">
  .PanoList .card-widget .widget-user-header h4 {
    color: #fff;
    text-shadow: 0 0 2px #000;
    font-size: 1rem;
  }

  .PanoList .card-widget .widget-user-header h5 {
    color: #fff;
    text-shadow: 0 0 2px #000;
    font-size: 0.8rem;
  }

  .PanoList .card-widget .widget-user-header h6 {
    color: #fff;
    text-shadow: 0 0 2px #000;
    font-size: 0.7rem;
  }

  .PanoList .card-widget .widget-user-header {
    position: relative;
  }

    .PanoList .card-widget .widget-user-header .widget-info {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

      .PanoList .card-widget .widget-user-header .widget-info span {
        padding: 0 0.2rem;
      }

  .PanoList .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
</style>
